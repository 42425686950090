import React from 'react'
import Layout from "../../components/Layout";
import {Container, Heading, Section, Box, Block, Image} from "react-bulma-components";
import serviciosSvg from "../../img/servicios.svg";
import responsiveSvg from "../../img/responsive.svg";
import appsSvg from "../../img/movil-web.svg";
import seoSvg from "../../img/seo.svg";
import botSvg from "../../img/chatbot.svg";
import { FaCheckDouble } from 'react-icons/fa';


const ServicesPage = () => (
    <Layout>
        <div
            className="full-width-image-container margin-top-0"
            style={{
                backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), url(${serviciosSvg})`,
                height: '20em'
            }}
        >
            <h2
                className="is-size-3-mobile is-size-2-tablet is-size-1-widescreen has-text-centered"
                style={{
                    boxShadow:
                        '#49509D 0.5rem 0px 0px, #49509D -0.5rem 0px 0px',
                    backgroundColor: '#49509D',
                    color: 'white',
                    lineHeight: '1',
                    padding: '0.25em',
                    borderRadius: '20px',

                }}
            >
                Servicios
            </h2>
        </div>
        <Block className="section has-text-centered">
            <Heading>
                Los que ofrecemos
            </Heading>
            <Heading subtitle>
                Brindamos una amplia gama de servicios {' '}
                <strong>
                    web y moviles
                </strong>
                , a la medida con tecnologias de vanguardia, explora
            </Heading>
        </Block>
        <Section>
            <Container>
                <Box>
                    <div className="columns">
                        <div className="column">
                            <Image
                                src={responsiveSvg}
                            />
                        </div>
                        <div className="column">
                            <h1 className="is-size-3">
                                Diseño web Responsive
                            </h1>
                            <br/>
                            <div className="colums">
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span style={{
                                        paddingLeft: '1.5em'
                                    }}>
                                Diseño UI, UX de interfaces de usuario para dispositivos y portatiles.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                               Diseño de mockups en herramientas como Figma, Sketch, Adobde XD.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                                Maquetado de aplicaciones web y moviles.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                                Diseño de sitios web empresariales, negocios, funnels etc..
                            </span>
                                </div>
                            </div>
                            <br/>
                            <div className="buttons is-centered">
                                <a className="button is-success"  rel="noreferrer" target="_blank" href="https://calendly.com/crisweb/first-talk">
                                    <strong>Cotiza</strong>
                                </a>
                            </div>
                        </div>
                    </div>
                </Box>
            </Container>
        </Section>
        <Section>
            <Container>
                <Box>
                    <div className="columns">
                        <div className="column">
                            <h1 className="is-size-3">
                                Aplicaciones web y moviles(software)
                            </h1>
                            <br/>
                            <div className="colums">
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span style={{
                                        paddingLeft: '1.5em'
                                    }}>
                                Creación de aplicaciones web, moviles, PWA, sitios web.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                               Desarrollo de frontend con Angular React y backend con Nodejs, Nestjs, Python.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span style={{
                                        paddingLeft: '1.5em'
                                    }}>
                                Desarrollo de Apliaciones móviles híbridas y nativas con ionic, react native, kotlin, Swift.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                                        Generador de sitios estáticos en tecnologias como Gatsby, Nextjs, Hugo.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                               Desarrollo de API's con Headless CMS como Ghost, Strapi, Netlify CMS
                            </span>
                                </div>
                            </div>
                            <br/>
                            <div className="buttons is-centered">
                                <a className="button is-success"  rel="noreferrer" target="_blank" href="https://calendly.com/crisweb/first-talk">
                                    <strong>Cotiza</strong>
                                </a>
                            </div>
                        </div>
                        <div className="column">
                            <Image
                                src={appsSvg}
                            />
                        </div>
                    </div>
                </Box>
            </Container>
        </Section>
        <Section>
            <Container>
                <Box>
                    <div className="columns">
                        <div className="column">
                            <Image
                                src={botSvg}
                            />
                        </div>
                        <div className="column">
                            <h1 className="is-size-3">
                                Diseño, Desarrollo y Entrenamiento de chatbots
                            </h1>
                            <br/>
                            <div className="colums">
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span style={{
                                        paddingLeft: '1.5em'
                                    }}>
                                Tu negocio 24/7 los 365 dias del año disponible a tus clientes.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                               Flujos desde preguntas frecuentes hasta transacciones como pedidos y reservas.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                                Mejora la atención al cliente con calidad y experiencia de usuario.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                                Incrementa el número de conversiones y ventas automáticas con tu chatbot en tu sitio o canales de chat.
                            </span>
                                </div>
                            </div>
                            <br/>
                            <div className="buttons is-centered">
                                <a className="button is-success"  rel="noreferrer" target="_blank" href="https://calendly.com/crisweb/first-talk">
                                    <strong>Cotiza</strong>
                                </a>
                            </div>
                        </div>
                    </div>
                </Box>
            </Container>
        </Section>
        <Section>
            <Container>
                <Box>
                    <div className="columns">
                        <div className="column">
                            <h1 className="is-size-3">
                                Posicionamiento SEO Sitios Web
                            </h1>
                            <br/>
                            <div className="colums">
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span style={{
                                        paddingLeft: '1.5em'
                                    }}>
                               Mejora la visivilidad de tu web en internet, redes sociales.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                               Consigue nuevos clientes gracias al posicionamiento SEO.
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                               Seguimiento y control del posicionamiento de tu Web y el de tu competencia
                            </span>
                                </div>
                                <div className="column">
                                    <FaCheckDouble className="icon-check"/>
                                    <span
                                        style={{
                                            paddingLeft: '1.5em'
                                        }}>
                               Aumenta el mayor número de visitas en tu sitio web, blog etc...
                            </span>
                                </div>
                            </div>
                            <br/>
                            <div className="buttons is-centered">
                                <a className="button is-success"  rel="noreferrer" target="_blank" href="https://calendly.com/crisweb/first-talk">
                                    <strong>Cotiza</strong>
                                </a>
                            </div>
                        </div>
                        <div className="column">
                            <Image
                                src={seoSvg}
                            />
                        </div>
                    </div>
                </Box>
            </Container>
        </Section>
    </Layout>
)

export default ServicesPage
